import { Grid, Typography } from "@material-ui/core";
import React from "react";
import signup1 from "../../assets/images/signup1.png";
import rebrand from "../../assets/images/rebranding_image.png";

import { themeColor } from "../../constants/themeColor";
import Login from "./Login";
import "./Login.scss";
import { useEffect, useState } from "react";
import { useAppContext } from "../../AppContext/App.context";
import useLocalStorage from "../../hooks/localStorage";

const LoginSignUpPage = () => {
  const [configImage, setConfigImage]: any = useState("");
  const [configText, setConfigText]: any = useState("");
  const [configCompanyName, setConfigCompanyName]: any = useState("");
  const [defaultData, setShowDefaultData]: any = useState(false);
  const [appData]: any = useAppContext();
  const customData: any = useLocalStorage.getItem("customData");

  useEffect(() => {
    if (
      Object.keys(appData?.formSettings).length > 0 ||
      Object.keys(customData).length > 0
    ) {
      if (
        (appData?.formSettings &&
          appData?.formSettings?.colors &&
          Object.keys(appData?.formSettings?.colors).length > 0) ||
        Object.keys(customData).length > 0
      ) {
        imageConfig();
        setShowDefaultData(false);
      } else {
        setShowDefaultData(true);
        setConfigImage(signup1);
        setConfigText("Welcome back! Sign in to continue");
        setConfigCompanyName("NetworkON");
      }
    } else {
      setConfigImage(signup1);
      setConfigText("Welcome back! Sign in to continue");
      setConfigCompanyName("NetworkON");
    }
  }, [appData.formSettings]);

  const imageConfig = () => {
    if (
      (appData.formSettings &&
        appData.formSettings.colors &&
        appData.formSettings.colors.login_bg_image) ||
      customData?.colors?.login_bg_image
    ) {
      setConfigImage(
        appData.formSettings.colors.login_bg_image ||
          customData?.colors?.login_bg_image
      );
    } else {
      setConfigImage(signup1);
    }
    if (
      (appData.formSettings &&
        appData.formSettings.colors &&
        appData.formSettings.colors.login_heading) ||
      customData?.colors?.login_heading
    ) {
      setConfigText(
        appData.formSettings.colors.login_heading ||
          customData?.colors?.login_heading
      );
    }
    if (
      (appData.formSettings &&
        appData.formSettings.colors &&
        appData.formSettings.colors.company_name) ||
      customData?.colors?.company_name
    ) {
      setConfigCompanyName(
        appData.formSettings.colors.company_name ||
          customData?.colors?.company_name
      );
    }
  };

  const setBackgroundImage = () => {
    return `url(${configImage})`;
  };
  return (
    <Grid container direction="row">
      <Grid
        item
        xs={6}
        style={{
          borderRight: "1px solid lightgrey",
          backgroundSize: appData?.formSettings?.colors
            ? "100% auto"
            : "contain",
          backgroundImage: setBackgroundImage(),
          backgroundRepeat: appData?.formSettings?.colors
            ? "no-repeat"
            : "repeat",
        }}
        className={"Login__Side-Image"}
      >
        {!appData?.formSettings?.colors && (
          <Grid
            container
            alignItems={"center"}
            direction="column"
            style={{ marginTop: "60px" }}
          >
            <Grid item xs={10}>
              <Typography
                style={{
                  fontSize: "36px",
                  fontWeight: "bold",
                  color: `${themeColor.whiteColor}`,
                }}
              >
                Amazing Delivery Experience
              </Typography>
            </Grid>

            <Grid>
              <Typography
                style={{
                  fontSize: "36px",
                  fontWeight: "bold",
                  color: `${themeColor.whiteColor}`,
                }}
              >
                For Your Customers And
              </Typography>
            </Grid>
            <Grid>
              <Typography
                style={{
                  fontSize: "36px",
                  fontWeight: "bold",
                  color: `${themeColor.whiteColor}`,
                }}
              >
                Your Teams
              </Typography>
            </Grid>
          </Grid>
        )}
      </Grid>

      <Grid item xs={6}>
        <Login text={configText} company={configCompanyName} />
      </Grid>
    </Grid>
  );
};

export default LoginSignUpPage;
