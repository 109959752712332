export const languageList = [
  {
    id: 1,
    code: "en",
    name: "English",
  },
  {
    id: 2,
    code: "es",
    name: "Spanish",
  },
];
export const languageObj: any = {
  "1": "en",
  "2": "es",
};

export const componentList = [
  { name: "Signup", id: 1, displayName: "Sign Up" },
  { name: "login", id: 2, displayName: "Sign In" },
  { name: "Common", id: 3, displayName: "Common" },
  { name: "Createjob", id: 4, displayName: "Create Job" },
  { name: "LoginViaOTP", id: 5, displayName: "Login Via OTP" },
  { name: "Jobfilter", id: 6, displayName: "Job Filter" },
  { name: "Jobslist", id: 7, displayName: "Jobs List" },
  { name: "TaskDetail", id: 8, displayName: "Task Detail" },
  { name: "Sidebar", id: 9, displayName: "Sidebar" },
  { name: "Contactlist", id: 10, displayName: "Contactlist" },
  { name: "Analytics", id: 11, displayName: "Analytics" },
];
export const componentObj: any = {
  1: "Signup",
  2: "Login",
  3: "Common",
  4: "Createjob",
  5: "LoginViaOTP",
  6: "Jobfilter",
  7: "Jobslist",
  8: "TaskDetail",
  9: "Sidebar",
  10: "Contactlist",
  11: "Analytics",
};
