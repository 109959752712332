import React, { useEffect, useState, Fragment, useRef, Suspense } from "react";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom";
import { themeColor } from "../../constants/themeColor";
import classes from "./Header.module.scss";
import PublishIcon from "@material-ui/icons/Publish";
import * as actions from "../../AppContext/actions/actions";
import clsx from "clsx";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

// import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import CachedIcon from "@material-ui/icons/Cached";
import TeamListDropdown from "../../components/TeamListDropdown/TeamListDropdown";
import ImportExportIcon from "@material-ui/icons/ImportExport";

import UserTypeDropdown from "../../components/UserTypeDropdown/UserTypeDropdown";
import ResourceListDropdownForHeader from "../../components/ResourceListDropdownForHeader/ResourceListDropdownForHeader";
import headerDropdownService from "../../services/headerDropdown.service";
import Btn from "../Button/Button";
import { socketToasterMessageSubject } from "../../utils/socket";
import useToastRight from "../ToastRight/hooks/useToastRight";
//import useToast from "../Toast/hooks/useToast";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import AddCircleRoundedIcon from "@material-ui/icons/AddCircleRounded";

import { makeStyles } from "@material-ui/core/styles";
import useLocalStorage from "../../utils/localStorage";
import { useAppContext } from "../../AppContext/App.context";
import useApiService from "../../services/api.service";
import CommonEndpoints from "../../services/commonApi.service";
import { userPermissionRoles } from "../../constants/userPermissionRoles";
import Input from "../../components/Input/Input";
import DateTimeFormatter from "../../utils/DateFormatter";
// import {MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
import DateTimePicker from "../../components/TemplatesCommonMethods/DateTimePicker";
import { useForm } from "react-hook-form";
import MomentUtils from "@date-io/moment";
import CalendarTodayIcon from "@material-ui/icons/CalendarTodayOutlined";
import TeamDropdownIcon from "../../assets/images/header/team_dropdown_icon.svg";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import { useTranslation } from "react-i18next";

import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuList from "@material-ui/core/MenuList";
import DateRangePopup from "../DateRangePopup/DatRangePopup";
import DateRangePopupHeader from "../DateRangePopup/DateRangePopupHeader";
import Dropdown from "../Dropdown/Dropdown";
import appEndpoints from "../../App.endpoints";
import { preLoginRoutes } from "../../constants/preLoginRoutes";
import { updateGoogleAPIKey } from "../../utils/updateGoogleAPIKey";
import Tooltip from "../Tooltip/Tooltip";
// import useLoader from "../../hooks/useLoader";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },

  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 7, 3),
    width: "500px",
    outline: "none",
  },
  search: {
    width: "100px",
    height: "10px",
  },
}));
// import Header from "../Header/Header";
const Header = (props: any) => {
  const { t, i18n } = useTranslation(["Header", "Common"]);

  const [selectedTeamId, setTeamId] = useState("");
  const [appData, dispatch]: any = useAppContext();
  const location = useLocation();
  const history = useHistory();
  const toasterRight = useToastRight();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorImportExportCustomer, setAnchorImportExportCustomer] =
    React.useState(null);
  const [anchorImportExport, setAnchorImportExport] = React.useState(null);
  const loginData: any = useLocalStorage.getItem("loginData");
  const customSettings: any = useLocalStorage.getItem("CustomData");

  const Permission: any = useLocalStorage.getItem("Permission");
  const isImportShow = customSettings?.allow_import;

  const ApiService = useApiService();
  const [profileImage, setProfileImage]: any = useState("");
  let currentEnDate: any = new Date();
  currentEnDate = DateTimeFormatter.changeOnlyFormatInSameTimeZone(
    currentEnDate,
    "YYYY-MM-DD"
  );
  // const loader: any = useLoader()
  const {
    register,
    control,
    handleSubmit,
    reset,

    errors,
    setValue,
  } = useForm();

  const newMinuteInDateForNewJob = new Date(
    new Date().setMinutes(new Date().getMinutes() + 20)
  );
  const [selectedDate, handleDateChange] = useState(newMinuteInDateForNewJob);

  const onDateChange = (event: any, data: any) => {
    // updateDateFromPicker(data);

    var newdate = event._d;
    var dateformatted = DateTimeFormatter.changeOnlyFormatInSameTimeZone(
      newdate,
      "YYYY-MM-DD"
    );
    const dropdownData = {
      type: "DateChanged",
      data: { date: dateformatted },
    };
    headerDropdownService.emit(dropdownData);
  };

  const showToasterMessage = (socketResponse: any) => {
    toasterRight.addToastRight({
      // message: t(`Header: ${socketResponse.message.toString()}`),
      message: socketResponse.message.toString(),
      timeout: 3000,
      type: "success",
    });
    return;
  };
  const handleSocketResponse = (socketResponse: any) => {
    switch (socketResponse.type) {
      case "toasterMessage":
        showToasterMessage(socketResponse);
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    if (loginData && !loginData.is_loaded) {
      loginData.is_loaded = 1;
      useLocalStorage.setItem("loginData", loginData);
    }

    if (
      (loginData && loginData.is_loaded) ||
      !preLoginRoutes.includes(location.pathname)
    ) {
      // getPermissions(loginData);
      //   checkCustomData();
    }
  }, []);
  const checkCustomData = () => {
    const domain = new URL(
      process.env.REACT_APP_CUSTOM_URL || "",
      window.location.href
    );
    const request: any = {
      url:
        window.location.host === "booking.networkon.tk"
          ? "https://demo.networkon.tk/"
          : "https://dpk.networkon.in",
    };
    let customData: any = {};
    return ApiService.get(appEndpoints.formSettings(request))
      .then(async (res: any) => {
        console.log(res);
        if (res.message === "success") {
          // await updateGoogleAPIKey(res.data[0]?.google_api_key);
          useLocalStorage.setItem("CustomData", res.data[0]);
          customData = useLocalStorage.getItem("CustomData");
        }
        useLocalStorage.setItem(
          "referenceId",
          res.data[0].customer_reference_id
        );
        useLocalStorage.setItem("langData", res.data.default_lang);
        console.log(customData);
        return true;
      })
      .catch((err: any) => {});
  };
  useEffect(() => {
    let headerSubscription = headerDropdownService.subscribe(
      dropdownUpdatedFromHeader
    );
    let socketSubscription: any = socketToasterMessageSubject.subscribe(
      (res: any) => {
        handleSocketResponse(res);
      }
    );
    // let headerSubscriptionimage = headerDropdownService.subscribe(eventFromService);
    // console.log(headerSubscriptionimage);

    return () => {
      headerSubscription.unsubscribe();
      socketSubscription.unsubscribe();
      //  headerSubscriptionimage.unsubscribe();
    };
  }, []);
  const getPermissions = async (loginData: any) => {
    let headers = {
      access_token: loginData.access_token,
    };
    let qureyParams = {
      role_id: loginData.role_id,
      limit: 1,
      skip: 0,
    };
    let reqData = {
      reqBody: qureyParams,
      headers: headers,
    };
    const res: any = await ApiService.get(
      CommonEndpoints.getRolesList(reqData)
    );
    const roleDetail: any = res.data.data[0];
    var TabPermission: any = {};
    let modpermsvalues: any = Object.values(roleDetail.modperms);
    modpermsvalues.map((mode: any) => {
      let moduleName: any =
        mode.module_alias.charAt(0).toUpperCase() +
        mode.module_alias.slice(1).replace(/_/g, "");
      for (var key in mode.permission) {
        TabPermission[`${userPermissionRoles[key].name}_${moduleName}`] =
          mode.permission[key].access_type;
      }
    });
    useLocalStorage.setItem("Permission", TabPermission);
    return true;
  };
  const ENPDropdown: any = [
    {
      value: "Earning",
    },
    {
      value: "Pricing",
    },
  ];
  const [enpDropdownValue, setEnpDropdownValue]: any = useState(
    window.location.href.indexOf("earning") > -1
      ? ENPDropdown[0]["value"]
      : ENPDropdown[1]["value"]
  );
  const dropdownUpdatedFromHeader = (dropdownData: any) => {
    switch (dropdownData.type) {
      case "teamList":
        teamListDropdownUpdated(dropdownData.data);
        break;
      case "ProfileImage":
        setProfileImage(dropdownData.value);
        break;
      default:
        break;
    }
  };

  const triggerActionFromHeader = (action: string) => {
    const dropdownData = {
      type: action,
      data: { clicked: true },
    };
    headerDropdownService.emit(dropdownData);
    //headerDropdownService.setData(dropdownData);
  };
  const handleImportExportCustomerClose = () => {
    setAnchorImportExportCustomer(null);
  };
  const teamListDropdownUpdated = (data: any) => {
    setTeamId(data.teamId);
  };
  const handleImportExportClose = () => {
    setAnchorImportExport(null);
  };
  const handleImportExportClick = (event: any) => {
    setAnchorImportExport(event.currentTarget);
  };
  const handleImportExportCustomerClick = (event: any) => {
    setAnchorImportExportCustomer(event.currentTarget);
  };
  const logoutFromDashboard = () => {
    //localStorage.clear();
    dispatch(actions.onLogout());
    window.location.href = "/login";
  };
  const muiClasses = useStyles();
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    handleImportExportClose();
  };
  const editProfileOpenHandler = () => {
    history.push("/editprofile");
    setAnchorEl(null);
  };

  const updateDateFromPicker = (data: any) => {
    handleDateChange(data);
    //  let str=data._d;
    //  let newdate=DateTimeFormatter.changeOnlyFormatInSameTimeZone(str,'YYYY/MM/DD');
    //  console.log(newdate);
  };

  return (
    <Fragment>
      <div className={classes["header"]}>
        <div className={classes["header__flex"]}>
          <div className={classes["header__flex__left-side"]}>
            <div>
              {location.pathname === "/dashboard" &&
              Permission &&
              Permission["Read_Teams"] ? (
                <TeamListDropdown
                  default={t("Header:All Teams")}
                ></TeamListDropdown>
              ) : (
                ""
              )}
            </div>
            <div>
              {location.pathname == "/settings/roles" ? (
                <UserTypeDropdown
                  default={t("Header:All User Types")}
                ></UserTypeDropdown>
              ) : (
                ""
              )}
            </div>
            {location.pathname == "/jobs/create"
              ? triggerActionFromHeader("clickedOnCreateTask")
              : ""}
            <div>
              {location.pathname == "/settings/autoallocation" &&
              Permission &&
              Permission["Read_Teams"] ? (
                <TeamListDropdown
                  default={t("Header:Default")}
                ></TeamListDropdown>
              ) : (
                ""
              )}
            </div>
            <div className={classes["header__flex__item-spacing"]}>
              {location.pathname == "/dashboard" &&
              Permission &&
              Permission["Read_Resources"] ? (
                // <ResourceListDropdownForHeader  inputProps={{ 'aria-label': 'naked' }} showAllResource={true} teamId={selectedTeamId} showIcon={true} variant={"standard"} location="header"></ResourceListDropdownForHeader>:null
                <ResourceListDropdownForHeader
                  teamId={selectedTeamId}
                ></ResourceListDropdownForHeader>
              ) : null}
              {location.pathname.includes("/analytics/job") &&
              Permission &&
              Permission["Read_Teams"] ? (
                <ResourceListDropdownForHeader
                  teamId={selectedTeamId}
                ></ResourceListDropdownForHeader>
              ) : (
                ""
              )}
            </div>
            {location.pathname === "/analytics" ? <DateRangePopupHeader /> : ""}

            {location.pathname === "/routes/list" ? (
              <div className={classes.pickerIcon}>
                <CalendarTodayIcon
                  className={classes["dropdown-pre-icon"]}
                  //style={{ color: themeColor.iconsColor }}
                />
                <DateTimePicker
                  type={2}
                  inputVariant="standard"
                  name="datePicker"
                  value={selectedDate}
                  onChange={(event: any, data: any) => {
                    onDateChange(event, data);
                  }}
                />
              </div>
            ) : (
              ""
            )}
            {location.pathname === "/analytics/overview" ? (
              <div style={{ color: "black" }} className={classes.pickerIcon}>
                {currentEnDate}
              </div>
            ) : (
              ""
            )}
            {location.pathname === "/analytics/resource" ? (
              <div className={classes["header__flex__item-spacing"]}>
                <div className={classes.pickerIcon}>
                  <CalendarTodayIcon
                    className={classes["dropdown-pre-icon"]}
                    //style={{ color: themeColor.iconsColor }}
                  />

                  <DateTimePicker
                    type={2}
                    inputVariant="standard"
                    name="datePicker"
                    value={selectedDate}
                    onChange={(event: any, data: any) => {
                      onDateChange(event, data);
                    }}
                  />
                </div>
              </div>
            ) : (
              ""
            )}
          </div>

          <div className={classes["header__notification-corner__image"]}>
            {/* <img
              className={
                classes["header__notification-corner__image__image-cover"]
              }
              src={BellIcon}
              alt="notifation bell icon"
            /> */}
            {location.pathname === "/contacts" ? (
              <Btn
                className={classes.btn}
                onClick={() => {
                  triggerActionFromHeader("clickedOnAddContact");
                }}
                type={"button"}
                btnText={t("Common:Add Contact")}
              ></Btn>
            ) : (
              ""
            )}

            {location.pathname === "/settings/teams" &&
            Permission &&
            Permission["Create_Teams"] ? (
              <Btn
                className={classes.btn}
                onClick={() => {
                  triggerActionFromHeader("clickedOnAddTeam");
                }}
                type={"button"}
                btnText={t("Header:Add Team")}
              ></Btn>
            ) : (
              ""
            )}

            <div style={{ display: "flex", alignItems: "center" }}>
              {location.pathname === "/jobs/list" && (
                <>
                  <Btn
                    className={classes["header__buttonRadius"]}
                    onClick={() => {
                      triggerActionFromHeader("clickedOnCreateTask");
                    }}
                    type={"button"}
                    startIcon={<AddCircleRoundedIcon />}
                    btnText={t("Header:New Job")}
                  ></Btn>
                  {isImportShow && (
                    <span>
                      <Tooltip
                        // content={`${
                        //   Permission["Create_Jobs"] && Permission["Read_Jobs"]
                        //     ? "Import/Export"
                        //     : Permission["Read_Jobs"]
                        //     ? "Export"
                        //     : Permission["Create_Jobs"]
                        //     ? "Import"
                        //     : ""
                        // }`}
                        content={"Import"}
                        direction={"bottom"}
                        style={{
                          backgroundColor: "rgba(97, 97, 97, 0.92)",
                          marginBottom: "-8px",
                          left: "65%",
                        }}
                        wrapperStyle={{
                          width: "-webkit-fill-available",
                        }}
                        //  className={muiClasses.tooltip}
                      >
                        <ImportExportIcon
                          onClick={(event: any) => {
                            handleImportExportClick(event);
                          }}
                          className={classes["header__logout-icon"]}
                          style={{ color: themeColor.iconsColor }}
                        />
                      </Tooltip>
                    </span>
                  )}
                </>
              )}

              {location.pathname === "/jobs/list" ? (
                <>
                  <CachedIcon
                    onClick={() => triggerActionFromHeader("refreshTaskList")}
                    className={classes["header__logout-icon"]}
                    style={{
                      color: themeColor.iconsColor,
                      // position: "absolute",
                      // right: "-10px",
                    }}
                  />
                </>
              ) : (
                ""
              )}
            </div>

            {/* <Menu
              className="pointer"
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClickOpen}>Edit Profile</MenuItem>
              <MenuItem onClick={logoutFromDashboard}>Logout</MenuItem>
            </Menu> */}
            <Popper
              open={Boolean(anchorImportExport)}
              anchorEl={anchorImportExport}
              role={undefined}
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "center top" : "center bottom",
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList id="menu-list-grow">
                        {/* {Permission["Create_Jobs"] ? ( */}
                        <MenuItem
                          onClick={() => {
                            triggerActionFromHeader("openImportModal");
                            handleImportExportClose();
                          }}
                        >
                          {t("Header:Import Jobs")}
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
            <Popper
              open={Boolean(anchorImportExportCustomer)}
              anchorEl={anchorImportExportCustomer}
              role={undefined}
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "center top" : "center bottom",
                  }}
                >
                  <Paper>
                    <ClickAwayListener
                      onClickAway={handleImportExportCustomerClose}
                    >
                      <MenuList id="menu-list-grow">
                        {/* {Permission["Create_Customers"] ? ( */}
                        <MenuItem
                          onClick={() => {
                            triggerActionFromHeader("openCustomerImport");
                            handleImportExportCustomerClose();
                          }}
                        >
                          {t("Header:Import")}
                        </MenuItem>
                        {/* ) : (
                          ""
                        )} */}
                        {/* {Permission && Permission["Read_Customers"] ? (
                          <MenuItem
                            onClick={() => {
                              triggerActionFromHeader("openCustomerExport");
                              handleImportExportCustomerClose();
                            }}
                          >
                            {t("Header:Export")}
                          </MenuItem>
                        ) : (
                          ""
                        )} */}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>

            <Popper
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              role={undefined}
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "center top" : "center bottom",
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList id="menu-list-grow">
                        <MenuItem onClick={editProfileOpenHandler}>
                          {t("Header:Edit Profile")}
                        </MenuItem>
                        <MenuItem onClick={logoutFromDashboard}>
                          {t("Header:Logout")}
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
            <div
              onClick={handleClick}
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              {(loginData && loginData.user_image) || profileImage ? (
                <img
                  style={{
                    height: "30px",
                    width: "30px",
                    borderRadius: "25px",
                    border: "2px solid #fffff",
                  }}
                  className={classes["header__logout-icon"]}
                  src={profileImage || loginData.user_image}
                  alt="hi"
                  // onClick={handleClick}
                ></img>
              ) : (
                <AccountCircleIcon
                  className={classes["header__logout-icon"]}
                  style={{ color: themeColor.iconsColor }}
                  fontSize="large"
                ></AccountCircleIcon>
              )}
              <span
                style={{
                  color: "#131523",
                  marginLeft: "10px",
                  maxWidth: "150px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "hidden",
                }}
                className={classes.loginData_name_container}
              >
                {loginData?.name || ""}
              </span>
            </div>
            <KeyboardArrowDownIcon
              onClick={handleClick}
              style={{ color: "#131523", cursor: "pointer" }}
            />

            {/* <ExitToAppIcon
              onClick={logoutFromDashboard}
              className={classes["header__logout-icon"]}
              style={{ color: themeColor.iconsColor }}
            ></ExitToAppIcon> */}
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default Header;
