import React, { useState, Fragment, useRef, useEffect } from "react";
import "./Signup.scss";
import { useForm } from "react-hook-form";
import logo from "../../assets/images/logo.svg";

import Input from "../../components/Input/Input";
import { ValidationService } from "../../utils/Validation";
import Btn from "../../components/Button/Button";
import Phone from "../../components/phone-picker/phone-picker";
import useApiService from "../../services/api.service";
import { useTranslation } from "react-i18next";
import useLoader from "../../hooks/useLoader";
import SigninEndpoints from "./Signup.endpoints";
import useToast from "../../components/Toast/hooks/useToast";
import { useHistory } from "react-router-dom";
import useLocalStorage from "../../utils/localStorage";
import { useAppContext } from "../../AppContext/App.context";
import signup1 from "../../assets/images/signup1.png";
import { Grid, Typography } from "@material-ui/core";
import { themeColor } from "../../constants/themeColor";
import rebrand from "../../assets/images/rebranding_image.png";

const Register = () => {
  const { t, i18n } = useTranslation(["Signup"]);
  const { register, handleSubmit, errors } = useForm();
  const loader: any = useLoader();
  const ApiService = useApiService();
  const customData = useLocalStorage.getItem("CustomData");
  const [configSignupText, setConfigSignupText]: any = useState("");
  const toaster = useToast();
  const history = useHistory();
  const [appData]: any = useAppContext();

  // const [showPassword, setShowPassword] = useState(false);
  // const handleClickShowPassword = () => {
  //   setShowPassword(!showPassword);
  // };
  const [configImage, setConfigImage]: any = useState("");

  useEffect(() => {
    if (Object.keys(appData?.formSettings).length < 1) {
      setConfigImage(signup1);
      setConfigSignupText("Hello! Sign up to get started");
    } else {
      if (
        appData?.formSettings &&
        appData?.formSettings?.colors &&
        Object.keys(appData?.formSettings?.colors).length > 0
      ) {
        imageConfig();
      } else {
        setConfigSignupText("Hello! Sign up to get started");
        setConfigImage(signup1);
      }
    }
  }, [appData.formSettings]);

  const imageConfig = () => {
    if (
      appData.formSettings &&
      appData.formSettings.colors &&
      appData.formSettings.colors.login_bg_image
    ) {
      setConfigImage(appData.formSettings.colors.login_bg_image);
    } else {
      setConfigImage(signup1);
    }
    if (
      appData.formSettings &&
      appData.formSettings.colors &&
      appData.formSettings.colors.signup_heading
    ) {
      setConfigSignupText(appData.formSettings.colors.signup_heading);
    }
  };

  const setBackgroundImage = () => {
    return `url(${configImage})`;
  };

  const dialCode: any = useRef<any>();
  const countryCode: any = useRef();
  const [preSavedCountryCode, updatePreSavedCountryCode]: any = useState();
  const obj = {
    name: {
      required: ValidationService.requiredValidator(),
    },
    email: {
      required: ValidationService.requiredValidator(),
      pattern: ValidationService.emailValidator(),
    },
    phone: {
      required: ValidationService.requiredValidator(),
    },
  };

  const fetchDialCode = (dialcode: any, countrycode: any) => {
    dialCode.current = "+" + dialcode;
    countryCode.current = countrycode.toUpperCase();
  };

  // Submit Form
  const submitSignupForm = (data: any) => {
    if (!data.name.trim()) {
      toaster.addToast({
        message: "Name is invalid",
        timeout: 3000,
        type: "error",
      });
      return;
    }
    const request: any = {
      country_code: dialCode.current,
      phone_number: data.phone,
      app_version: "100.1",
      device_details: "1",
      name: data.name.trim(),
      email: data.email,
    };
    const headers = {
      reference_id: useLocalStorage.getItem("referenceId"),
    };
    const reqData = {
      reqBody: request,
      headers: headers,
    };
    loader.showLoader();
    return ApiService.post(SigninEndpoints.signin(reqData))
      .then(async (res: any) => {
        loader.hideLoader();
        console.log(res);

        if (res.message === "success") {
          // useLocalStorage.setItem("loginData", res.data);
          // useLocalStorage.setItem("userAccessToken", res.data.access_token);
          if (appData.loginData) {
            history.push(`/checkotp/${dialCode.current}/${data.phone}`);
          }
        } else {
        }
      })
      .catch((err) => {
        console.log(err);
        loader.hideLoader();
        toaster.addToast({
          message: err.message,
          timeout: 3000,
          type: "error",
        });
      });
  };

  return (
    <Grid container direction="row" className="SignUp">
      <Grid
        item
        xs={6}
        style={{
          borderRight: "1px solid lightgrey",
          backgroundSize: appData?.formSettings?.colors
            ? "100% auto"
            : "contain",
          backgroundImage: setBackgroundImage(),
          backgroundRepeat: appData?.formSettings?.colors
            ? "no-repeat"
            : "repeat",
          minHeight: appData?.formSettings?.colors ? "100vh" : "100vh",
        }}
        className={"Login__Side-Image"}
      >
        {!appData?.formSettings?.colors && (
          <Grid
            container
            alignItems={"center"}
            direction="column"
            style={{ marginTop: "60px" }}
          >
            <Grid item xs={10}>
              <Typography
                style={{
                  fontSize: "36px",
                  fontWeight: "bold",
                  color: `${themeColor.whiteColor}`,
                }}
              >
                Amazing Delivery Experience
              </Typography>
            </Grid>

            <Grid>
              <Typography
                style={{
                  fontSize: "36px",
                  fontWeight: "bold",
                  color: `${themeColor.whiteColor}`,
                }}
              >
                For Your Customers And
              </Typography>
            </Grid>
            <Grid>
              <Typography
                style={{
                  fontSize: "36px",
                  fontWeight: "bold",
                  color: `${themeColor.whiteColor}`,
                }}
              >
                Your Teams
              </Typography>
            </Grid>
          </Grid>
        )}
      </Grid>

      <Grid item xs={6}>
        <div style={{ overflow: "auto" }}>
          <div
            className={"logo-image-signup"}
            style={{ margin: "50px 0px", textAlign: "center" }}
          >
            <img
              // className="align-self-center Login__logo"
              src={
                customData && customData.login_icon
                  ? customData.login_icon
                  : logo
              }
              alt=""
              width={210}
            ></img>
          </div>

          <div className="Signup__body">
            <Typography className={"Signup__trial-heading-text"}>
              {" "}
              {configSignupText
                ? configSignupText
                : "Hello! Sign up to get started"}
            </Typography>
            <div className="Signup__card">
              <Fragment>
                <form onSubmit={handleSubmit(submitSignupForm)}>
                  <Input
                    type="text"
                    id="name"
                    name="name"
                    refValue={register(obj.name)}
                    errors={errors}
                    placeholder="Enter Your Name"
                    label={t("Common:Name") + "*"}
                  ></Input>

                  <Input
                    type="email"
                    id="email"
                    name="email"
                    refValue={register(obj.email)}
                    errors={errors}
                    placeholder="Enter Your Email Address"
                    label={t("Common:Email Address") + "*"}
                  ></Input>
                  <Phone
                    hideLabel={true}
                    showPhone={true}
                    setDialCode={fetchDialCode}
                    name="phone"
                    label={t("Common:Mobile Number")}
                    placeholder="Mobile Number*"
                    required={true}
                    defaultPhone={preSavedCountryCode}
                    errors={errors}
                    refValue={register(obj.phone)}
                  ></Phone>
                  <div
                    style={{
                      textAlign: "center",
                      marginTop: "-10px",
                      // marginBottom: "-16px",
                    }}
                  >
                    <Btn
                      type={"Submit"}
                      btnText={t("Signup:Sign up")}
                      className={"Signup__btn"}
                    />
                  </div>
                </form>
              </Fragment>
            </div>
            <div
              className="Signup__login my-4"
              onClick={() => history.push("/login")}
            >
              {t("Signup: Already have an Account? Sign in")}
            </div>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default Register;
