const LoginEndpoints = {
  login(data: any) {
    return {
      // API_BASE_URL:"http://54.225.194.218:3000/",
      url: "customer/customer/login",
      data: data["reqBody"],
      headers: data["headers"],
    };
  },
  sendOTP(data: any) {
    return {
      url: "customer/customer/sendOtp",
      data: data["reqBody"],
      headers: data["headers"],
    };
  },
  otpValidation(data: any) {
    return {
      url: "customer/customer/otpValidation",
      data: data["reqBody"],
      headers: data["headers"],
    };
  },
  forgotPassword(data: object) {
    return {
      url: "auth/user/forgotPassword",
      data,
    };
  },
  socialLogin(data: object) {
    return {
      url: "social_login",
      data,
    };
  },
};

export default LoginEndpoints;
